import * as React from "react";
import type { ThemeUIStyleObject } from "theme-ui";

type CharacterProps = Character;
const Character: React.FC<CharacterProps> = React.memo(
  ({ char, unicode, withoutCurly, compound }) => {
    let characterToRender: string;

    if (withoutCurly === undefined) {
      characterToRender = char;
    } else if (compound === undefined) {
      characterToRender = withoutCurly;
    } else {
      characterToRender = compound;
    }

    return (
      <div className="character-container">
        <div className="character">{characterToRender}</div>
        <div className="hex-code">
          {unicode === undefined ? "Mix" : unicode}
        </div>
      </div>
    );
  }
);

export default Character;

export const characterEmotionStyles: ThemeUIStyleObject = {
  li: {
    margin: 1,
  },
  ".character-container": {
    bg: "muted",
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "100%",
  },
  ".character": {
    fontSize: 5,
  },
  ".hex-code": {
    color: "hexChar",
    fontSize: 2,
    textTransform: "uppercase",
  },
};
