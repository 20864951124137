/** @jsx jsx */
import * as React from "react";
import { Themed, jsx } from "theme-ui";
import { useVirtual } from "react-virtual";
import Character, { characterEmotionStyles } from "./Character";

interface CharacterGridProps {
  characters: Character[];
}
const CharacterGrid: React.FC<CharacterGridProps> = ({ characters }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const cellWidth = 110;
  const cellHeight = 100;
  const numberOfColumns =
    parentRef.current === null
      ? 11
      : Math.floor(parentRef.current.clientWidth / cellWidth);

  const rowVirtualizer = useVirtual({
    size: Math.ceil(characters.length / numberOfColumns),
    parentRef,
    estimateSize: React.useCallback(() => cellHeight, []),
    overscan: 5,
  });

  const columnVirtualizer = useVirtual({
    horizontal: true,
    size: numberOfColumns,
    parentRef,
    estimateSize: React.useCallback(() => cellWidth, []),
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      sx={{
        // position: "sticky",
        // top: 150,
        // react-virtual styles
        maxHeight: "120vh",
        width: "100%",
        overflow: "auto",
        gridColumn: "2/5 !important",
      }}
    >
      <Themed.ul
        tabIndex={0}
        sx={{
          listStyle: "none",
          my: 1,
          mx: "auto",
          p: 0,
          ":focus": {
            outline: "1px solid transparent",
            boxShadow: (theme) => `0 0 0 2px ${theme.colors?.accent}`,
          },
          // target with emotion
          ...characterEmotionStyles,

          // react-virtual styles
          height: `${rowVirtualizer.totalSize}px`,
          width: `${columnVirtualizer.totalSize}px`,
          position: "relative",
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => (
          <React.Fragment key={virtualRow.index}>
            {columnVirtualizer.virtualItems.map((virtualColumn) => {
              const oneDimensionalIndex =
                virtualRow.index * numberOfColumns + virtualColumn.index;
              const isCellInsideArray = oneDimensionalIndex < characters.length;

              return (
                isCellInsideArray && (
                  <li
                    key={virtualColumn.index}
                    // With emotion it gets really slow
                    style={{
                      // react-virtual styles
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: `${virtualColumn.size - 7}px`,
                      height: `${virtualRow.size - 7}px`,
                      transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <Character
                      char={characters[oneDimensionalIndex].char}
                      unicode={characters[oneDimensionalIndex].unicode}
                      withoutCurly={
                        characters[oneDimensionalIndex].withoutCurly
                      }
                      compound={characters[oneDimensionalIndex].compound}
                    />
                  </li>
                )
              );
            })}
          </React.Fragment>
        ))}{" "}
      </Themed.ul>
    </div>
  );
};

export default CharacterGrid;
