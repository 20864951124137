/** @jsx jsx */
import * as React from "react";
import { Themed, jsx } from "theme-ui";
import type { PageProps } from "gatsby";
import { Link, graphql, useStaticQuery } from "gatsby";
import { z } from "zod";

import Layout from "../components/Layout";
import CharacterGrid from "../components/CharacterGrid";
import Character, { characterEmotionStyles } from "../components/Character";
import SEO from "../components/SEO";

const LanguageTemplateStaticQuerySchema = z.object({
  site: z.object({
    siteMetadata: z.object({
      title: z.string(),
      logo: z.string(),
      siteUrl: z.string(),
    }),
  }),
});

type LanguageTemplateStaticQuery = z.infer<
  typeof LanguageTemplateStaticQuerySchema
>;

type LanguageTemplateProps = PageProps<object, LanguageTemplateContext>;

const LanguageTemplate: React.FC<LanguageTemplateProps> = ({
  pageContext: {
    language: { name, code, characters, unicodeRanges },
  },
}) => {
  const data: LanguageTemplateStaticQuery = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          logo
          siteUrl
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { title, logo, siteUrl },
    },
  } = LanguageTemplateStaticQuerySchema.parse(data);

  return (
    <Layout
      headerContent={
        <div
          sx={{
            display: "flex",
            color: "text",
            px: [4, 5],
            pt: 4,
            pb: 5,
            borderBottom: (theme) => `1px solid ${theme.colors?.highlightCode}`,
          }}
        >
          <div
            sx={{
              maxWidth: "calc(var(--rhythm) * 42)",
              margin: "0 auto",
            }}
          >
            <Themed.h1 id={name} sx={{ mt: 0 }}>
              {name}
            </Themed.h1>
            <Themed.ul sx={{ li: { py: [1, 0] } }}>
              <Themed.li>
                {name} have <em>{characters.length}</em> unicode characters.
              </Themed.li>
              <Themed.li>
                The language code for {name} is <em>{code}</em>
              </Themed.li>
              <Themed.li>
                <Themed.a href="#character-table">Character table</Themed.a>
              </Themed.li>
              <Themed.li>
                <Themed.a href="#unicode-ranges">Unicode ranges</Themed.a>
              </Themed.li>
            </Themed.ul>
          </div>
        </div>
      }
    >
      <SEO
        title={`${name} character table | ${title}`}
        description={`A table that lists the characters used by the ${name} language. The data are from the Unicode CLDR project.`}
        image={`${siteUrl}/${logo}`}
        url={`${siteUrl}/${code}/`}
      />
      <Themed.h2
        id="character-table"
        sx={{ mt: 6, mb: 5, gridColumn: "2/5 !important", textAlign: "center" }}
      >
        Character table
      </Themed.h2>
      {characters.length > 300 ? (
        // react-virtual grid
        <CharacterGrid characters={characters} />
      ) : (
        // regular grid
        <Themed.ul
          sx={{
            px: 2,
            listStyle: "none",
            gridColumn: "2/5 !important",
            display: "grid",
            gridGap: 2,
            ...characterEmotionStyles,
            gridTemplateColumns: "repeat(auto-fit, minmax(90px, 1fr))",
          }}
        >
          {characters.map(({ char, unicode, withoutCurly, compound }) => (
            <Themed.li key={char} sx={{ m: "0 !important" }}>
              <Character
                char={char}
                unicode={unicode}
                withoutCurly={withoutCurly}
                compound={compound}
              />
            </Themed.li>
          ))}
        </Themed.ul>
      )}
      <Themed.h2
        id="unicode-ranges"
        sx={{ mt: 6, mb: 5, gridColumn: "2/5 !important", textAlign: "center" }}
      >
        Unicode Ranges
      </Themed.h2>
      <Themed.ul
        tabIndex={0}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          alignContent: "flex-start",
          maxHeight: "400px",
          overflow: "auto",
          gridColumn: "2/5 !important",
          ":focus": {
            outline: "1px solid transparent",
            boxShadow: (theme) => `0 0 0 2px ${theme.colors?.accent}`,
          },
        }}
      >
        {unicodeRanges.map((range) => (
          <Themed.li key={range} sx={{ minWidth: "220px" }}>
            {range}
          </Themed.li>
        ))}
      </Themed.ul>

      <Themed.h3 sx={{ textAlign: "center" }}>
        Copy for <abbr>CSS</abbr>
      </Themed.h3>
      <Themed.code sx={{ gridColumn: "2/5 !important" }}>
        {unicodeRanges.join(", ")}
      </Themed.code>

      <Link
        to="/"
        sx={{
          variant: "links.ui",
          gridColumn: "2 !important",
        }}
      >
        Back to homepage
      </Link>
    </Layout>
  );
};

export default LanguageTemplate;
